
    import {defineComponent, ref, reactive, toRaw, UnwrapRef, onMounted,onUpdated,getCurrentInstance} from 'vue';
    import {message} from "ant-design-vue";
    import {
        InboxOutlined,
    } from '@ant-design/icons-vue';
    import {submit, getDetail} from '@/network/seller';
    import baseUrl from "@/util/api"
    import {useStore} from "vuex";
    import {useRouter} from "vue-router";

    interface FormState {
        creator: string | null;
        sitetype: any;
        site: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        file: any;
        department: any;
        type: number;
    }

    interface FormState1 {
        creator: string | null;
        name: string;
        link1: string;
        link2: string;
        link3: string;
        link4: string;
        link5: string;
        link6: string;
        link7: string;
        link8: string;
        link9: string;
        link10: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        file: any;
        department: any;
        type: number;
    }

    interface FormState2 {
        creator: string | null;
        name: string;
        link1: string;
        link2: string;
        link3: string;
        link4: string;
        link5: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        file: any;
        department: any;
        type: number;
    }

    interface FileItem {
        name?: string;
        status?: string;
        response?: any;
        url?:string;
    }

    interface FileInfo {
        file: FileItem;
        fileList: FileItem[];
    }
    interface Value {
        value?: string;
        label?: string;
    }

    export default defineComponent({
        name: "Sadd",
        components: {
            InboxOutlined,
        },
        setup() {
            const internalInstance:any = getCurrentInstance();
            const submitting = ref<Boolean>(false);
            const submitting1 = ref<Boolean>(false);
            const submitting2 = ref<Boolean>(false);
            const store = useStore();
            const formRef = ref();
            const formRef1 = ref();
            const formRef2 = ref();
            const formState: UnwrapRef<FormState> = reactive({
                creator: window.localStorage.getItem('uid'),
                sitetype:undefined,
                site: '',
                need1: '',
                need2: '',
                need3: '',
                need4: '',
                need5: '',
                file: [],
                department: 1,
                type: 1,
            });
            const formState1: UnwrapRef<FormState1> = reactive({
                creator: window.localStorage.getItem('uid'),
                name: '',
                link1: '',
                link2: '',
                link3: '',
                link4: '',
                link5: '',
                link6: '',
                link7: '',
                link8: '',
                link9: '',
                link10: '',
                need1: '',
                need2: '',
                need3: '',
                need4: '',
                need5: '',
                file: [],
                department: 1,
                type: 2,
            });
            const formState2: UnwrapRef<FormState2> = reactive({
                creator: window.localStorage.getItem('uid'),
                name: '',
                link1: '',
                link2: '',
                link3: '',
                link4: '',
                link5: '',
                need1: '',
                need2: '',
                need3: '',
                need4: '',
                need5: '',
                file: [],
                department:1,
                type: 0,
            });
            const rules = {
                site: [
                    {required: true, message: '请输入网站名称', trigger: 'blur'}
                ],
                //need1: [{required: true, message: '请输入任务要求', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                sitetype: [{type: 'string', required: true, message: '请选择建站类型', trigger: 'blur'}],
            };
            const rules1 = {
                name: [
                    {required: true, message: '请输入产品名称', trigger: 'blur'}
                ],
                //link1: [{required: true, message: '请输入产品链接', trigger: 'blur'}],
                //need1: [{required: true, message: '请输入任务要求', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                department: [{type: 'string', required: true, message: '请选择工单处理部门', trigger: 'blur'}],
            };
            const rules2 = {
                name: [
                    {required: true, message: '请输入产品名称', trigger: 'blur'}
                ],
                //link1: [{required: true, message: '请输入产品链接', trigger: 'blur'}],
                //need1: [{required: true, message: '请输入任务要求', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                department: [{type: 'string', required: true, message: '请选择工单处理部门', trigger: 'blur'}],
            };
            const fileList = ref<FileItem[]>([]);
            const fileList1 = ref<FileItem[]>([]);
            const fileList2 = ref<FileItem[]>([]);
            const onSubmit = () => {
                formRef.value
                    .validate().then(() => {
                    submitting.value = true;
                    submit({...formState,id:id}).then((response) => {
                        if (response.status == 'done') {
                            internalInstance?.proxy.$socket.sendObj({
                                type: 'text',
                                msg: "你有新的任务，待审核！",
                                mode:2,
                                creator:window.localStorage.getItem('uname'),
                            });
                            fileList.value = [];
                            formState.file = [];
                            resetForm();
                            message.success(`${response.msg} `);
                            back();
                        } else {
                            message.error(`${response.msg} `);
                        }
                        submitting.value = false;
                    })
                })
                //console.log('submit!', toRaw(formState));
            };
            const onSubmit1 = () => {
                formRef1.value
                    .validate().then(() => {
                    submitting1.value = true;
                    submit({...formState1,id:id}).then((response) => {
                        if (response.status == 'done') {
                            internalInstance?.proxy.$socket.sendObj({
                                type: 'text',
                                msg: "你有新的任务！",
                                mode:3,
                                creator:window.localStorage.getItem('uname'),
                            });
                            message.success(`${response.msg} `);
                            fileList1.value = [];
                            formState1.file = [];
                            resetForm1();
                            back();
                        } else {
                            message.error(`${response.msg} `);
                        }
                        submitting1.value = false;
                    })
                })
                //console.log('submit!', toRaw(formState1));
            };
            const onSubmit2 = () => {
                formRef2.value
                    .validate().then(() => {
                    submitting2.value = true;
                    submit({...formState2,id:id}).then((response) => {
                        if (response.status == 'done') {
                            internalInstance?.proxy.$socket.sendObj({
                                type: 'text',
                                msg: "你有新的任务！",
                                mode:3,
                                creator:window.localStorage.getItem('uname'),
                            });
                            message.success(`${response.msg} `);
                            fileList2.value = [];
                            formState2.file = [];
                            resetForm2();
                            back();
                        } else {
                            message.error(`${response.msg} `);
                        }
                        submitting2.value = false;
                    })
                })
                //console.log('submit!', toRaw(formState1));
            };
            const back = ()=>{
                if(id){
                    router.push('/admin/seller/list');
                }
            }
            const resetForm = () => {
                formRef.value.resetFields();
            };
            const resetForm1 = () => {
                formRef1.value.resetFields();
            };
            const resetForm2 = () => {
                formRef2.value.resetFields();
            };
            const handleImgChange = (info: FileInfo) => {
                console.log(info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState.file.indexOf(file.response.uploadImg);
                //console.log(index);
                //console.log(formState.file);
                formState.file.splice(index, 1);

            }
            const handleImgChange1 = (info: FileInfo) => {
                console.log(info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState1.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove1 = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState1.file.indexOf(file.response.uploadImg);
                //console.log(index);
                formState1.file.splice(index, 1);

            }
            const handleImgChange2 = (info: FileInfo) => {
                console.log(info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState2.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove2 = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState2.file.indexOf(file.response.uploadImg);
                //console.log(index);
                formState2.file.splice(index, 1);

            }
            const handleChange = (value: Value) => {
                formState.sitetype = value; // { key: "lucy", label: "Lucy (101)" }
            };
            const handleChange1 = (value: Value) => {
                formState1.department = value; // { key: "lucy", label: "Lucy (101)" }
            };
            const handleChange2 = (value: Value) => {
                formState2.department = value; // { key: "lucy", label: "Lucy (101)" }
            };
            const activeKey = ref<string>('1');
            const router = useRouter();
            let id = router.currentRoute.value.query.id ? router.currentRoute.value.query.id : '';
            let dis = router.currentRoute.value.query.dis ? router.currentRoute.value.query.dis : false;
            const get = () => {
                getDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        console.log(response);
                        if (response.data.type == 0) {
                            activeKey.value = '3';
                            formState2.name = response.data.name;
                            formState2.link1 = response.data.link1;
                            formState2.link2 = response.data.link2;
                            formState2.link3 = response.data.link3;
                            formState2.link4 = response.data.link4;
                            formState2.link5 = response.data.link5;
                            formState2.need1 = response.data.need1;
                            formState2.need2 = response.data.need2;
                            formState2.need3 = response.data.need3;
                            formState2.need4 = response.data.need4;
                            formState2.need5 = response.data.need5;
                            if (response.data.file) {
                                formState2.file = response.data.file.split(',');
                                fileList2.value = [];
                                var nowfile = response.data.file.split(',');
                                for (let i = 0; i < nowfile.length; i++) {
                                   console.log(fileList2.value[i]);
                                   const temp = {
                                       name:nowfile[i].substring(nowfile[i].lastIndexOf("/")+1),
                                       status:"done",
                                       url:baseUrl.apiUrl + nowfile[i],
                                       response:{uploadImg:nowfile[i]}
                                   }
                                   fileList2.value.push(temp);
                               }
                            }
                            formState2.department = response.data.department.toString();
                        } else if (response.data.type == 1) {
                            activeKey.value = '1';
                            formState.sitetype = response.data.sitetype.toString();
                            formState.site = response.data.site;
                            formState.need1 = response.data.need1;
                            formState.need2 = response.data.need2;
                            formState.need3 = response.data.need3;
                            formState.need4 = response.data.need4;
                            formState.need5 = response.data.need5;
                            if (response.data.file) {
                                formState.file = response.data.file.split(',');
                                fileList.value = [];
                                var nowfile = response.data.file.split(',');
                                for (let i = 0; i < nowfile.length; i++) {
                                    //console.log(fileList2.value[i]);
                                    const temp = {
                                        name:nowfile[i].substring(nowfile[i].lastIndexOf("/")+1),
                                        status:"done",
                                        url:baseUrl.apiUrl + nowfile[i],
                                        response:{uploadImg:nowfile[i]}
                                    }
                                    fileList.value.push(temp);
                                }
                            }
                            formState.department = response.data.department.toString();
                        } else if (response.data.type == 2) {
                            activeKey.value = '2';
                            formState1.name = response.data.name;
                            formState1.link1 = response.data.link1;
                            formState1.link2 = response.data.link2;
                            formState1.link3 = response.data.link3;
                            formState1.link4 = response.data.link4;
                            formState1.link5 = response.data.link5;
                            formState1.link6 = response.data.link6;
                            formState1.link7 = response.data.link7;
                            formState1.link8 = response.data.link8;
                            formState1.link9 = response.data.link9;
                            formState1.link10 = response.data.link10;
                            formState1.need1 = response.data.need1;
                            formState1.need2 = response.data.need2;
                            formState1.need3 = response.data.need3;
                            formState1.need4 = response.data.need4;
                            formState1.need5 = response.data.need5;
                            if (response.data.file) {
                                formState1.file = response.data.file.split(',');
                                fileList1.value = [];
                                var nowfile = response.data.file.split(',');
                                for (let i = 0; i < nowfile.length; i++) {
                                    //console.log(fileList2.value[i]);
                                    const temp = {
                                        name:nowfile[i].substring(nowfile[i].lastIndexOf("/")+1),
                                        status:"done",
                                        url:baseUrl.apiUrl + nowfile[i],
                                        response:{uploadImg:nowfile[i]}
                                    }
                                    fileList1.value.push(temp);
                                }
                            }
                            formState1.department = response.data.department.toString();
                        }
                    } else {
                        resetForm();
                        fileList.value = [];
                        resetForm1();
                        fileList1.value = [];
                        resetForm2();
                        fileList2.value = [];
                    }
                })
            }
            onMounted(() => {
                if (id) {
                    get();
                }
            })
            onUpdated(() => {
                //get();
            })
            return {
                activeKey,
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
                formRef,
                formRef1,
                formRef2,
                formState,
                formState1,
                formState2,
                onSubmit,
                onSubmit1,
                onSubmit2,
                fileList,
                fileList1,
                fileList2,
                handleImgChange,
                handleFileRemove,
                handleImgChange1,
                handleFileRemove1,
                handleImgChange2,
                handleFileRemove2,
                rules,
                rules1,
                rules2,
                resetForm,
                resetForm1,
                resetForm2,
                handleChange,
                handleChange1,
                handleChange2,
                submitting,
                submitting1,
                submitting2,
                baseUrl: baseUrl.apiUrl,
                header: {
                    authorization: store.state.token,
                },
                dis,
            };
        },
    })
